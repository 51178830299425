import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useParams } from "react-router-dom";

const UserManagementContext = createContext();

export const UserManagementProvider = ({ children }) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [organizations, setOrganizations] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [currentOrg, setCurrentOrg] = useState(null);
  const [error, setError] = useState(null);
  const [isLoadingOrgs, setIsLoadingOrgs] = useState(true);
  const [hasOrgs, setHasOrgs] = useState('unknown');

  const { currentOrg: currentOrgParam } = useParams();

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchOrganizations = async () => {
      if (isAuthenticated && user && user.sub) {
        try {
          const response = await axios.post(
            `${API_BASE_URL}/api/GetPermissionsTrigger`,
            { user_id: user.sub },
            { headers: { 'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY } }
          );

          const { organizations: orgs } = response.data;
          setOrganizations(orgs);
          
          // Update hasOrgs based on fetched organizations
          setHasOrgs(orgs.length > 0);

          // if (!currentOrg && orgs.length > 0) {

          //   console.log(currentOrgParam)
          //   if (currentOrgParam) {
          //     console.log('Good Trigger')
          //     setCurrentOrg(orgs.find(org => org.name === currentOrgParam));
          //   } else {
          //     console.log('Bad Trigger')
          //     console.log(orgs[1])
          //     setCurrentOrg(orgs[0]);
          //   }
          // }

          const { permissions: perms } = response.data;
          setPermissions(perms);

        } catch (error) {
          console.error('Error fetching organizations:', error);
          setError('Failed to fetch organizations.');
        } finally {
          setIsLoadingOrgs(false);
        }
      }
    };

    fetchOrganizations();
  }, [isAuthenticated, user, API_BASE_URL]);

  useEffect(() => {
    if (currentOrg) {
      // Additional logic based on currentOrg can be added here
    }
  }, [currentOrg]);

  return (
    <UserManagementContext.Provider 
      value={{ 
        organizations, 
        currentOrg, 
        setCurrentOrg, 
        isLoadingOrgs, 
        error, 
        user, 
        isLoading, 
        permissions,
        hasOrgs // Provide hasOrgs in context
      }}>
      {children}
    </UserManagementContext.Provider>
  );
};

// Custom hook for using the UserManagementContext
export const useUserManagement = () => {
  return useContext(UserManagementContext);
};

export function useCheckPermissions() {
  const { organizations, permissions } = useUserManagement();

  function checkPermissions(requestedPermissions, organization) {
      // If organization is provided, check if an organization with the specified name exists
      if (organization) {
          const organizationExists = organizations.some(org => org.name === organization);
          if (!organizationExists) {
              return false;
          }
      }

      // Loop through each requested permission
      for (let requestedPermission of requestedPermissions) {
          // Check if a permission with the requested permission_name exists
          const permissionExists = permissions.some(perm => perm.permission_name === requestedPermission);
          if (!permissionExists) {
              return false;
          }
      }

      // All checks passed, return true
      return true;
  }

  return checkPermissions;
}
