import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import HeaderBar from './shared/HeaderBar';
import DynamicTable from './shared/DynamicTable';
import DynamicCards from './shared/DynamicCards';
import Blueprints from './subcomponents/Blueprints'
import { Container, Row, Col, Card, Spinner, Alert, Button, Modal, Form, Tab, Nav, Badge } from 'react-bootstrap';
import { Chip, Box } from '@mui/material';
import WorkspacesIcon from '@mui/icons-material/Dashboard';
import CategoryIcon from '@mui/icons-material/Category';
import SearchIcon from '@mui/icons-material/Search'
import SettingsIcon from '@mui/icons-material/Settings';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useUserManagement, useCheckPermissions } from '../contexts/UserManagementContexts';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const OrganisationLanding = () => {
  //const { user } = useAuth0();
  const { user, hasOrgs, organizations, currentOrg, setCurrentOrg, isLoadingOrgs, error } = useUserManagement();

  // Permission Check
  const checkPermissions = useCheckPermissions();
  const [hasCreatePermission, setHasCreatePermission] = useState(false); // If user is able to create new workspaces
  const [hasEditPermission, setHasEditPermission] = useState(false); // If user is able to edit workspaces
  const [hasDeletePermission, setHasDeletePermission] = useState(false); // If user is able to delete workspaces

  // Local states
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setAPIError] = useState(null);
  //const [currentOrg, setCurrentOrg] = useState(null);
  const [orgWorkspaces, setOrgWorkspaces] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const navigate = useNavigate();

  // Permissions check: Allow workspace creation only for admins
  const auth0AppMetadata = user?.['https://hoppa.ai/app_metadata'] || {};
  const resources = auth0AppMetadata.resources || {};

  let config = {
    headers: {
      'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
    }
  }

  // Permission check
  useEffect(() => {
    if (!isLoading && !isLoadingOrgs) {
      const isAuthorised = checkPermissions(['read:workspaces'], currentOrg.name)
      if (isAuthorised) {
        const createPermission = checkPermissions(['create:workspaces'], currentOrg.name)
        const editPermission = checkPermissions(['update:workspaces'], currentOrg.name)
        const deletePermission = checkPermissions(['delete:workspaces'], currentOrg.name)
        setHasCreatePermission(createPermission)
        setHasEditPermission(editPermission)
        setHasDeletePermission(deletePermission)
      } else {
        navigate('/access-denied')
      }
    }
  }, [user, isLoading, isLoadingOrgs, currentOrg]);


  useEffect(() => {
    // Only navigate if hasOrgs changes to false
    if (hasOrgs === false) {
      navigate(`/welcomeUser`);
    }
  }, [hasOrgs, navigate]);

  const handleOrgSwitch = (selectedOrg) => {
    setCurrentOrg(selectedOrg);
  };

  const fetchWorkspaces = async () => {
    if (currentOrg && currentOrg.name && user) {
      setIsLoading(true);
      setOrgWorkspaces([]);

      // Check if user access restricted to certain workspaces
      const auth0AppMetadata = user?.['https://hoppa.ai/app_metadata'] || {};
      const resources = auth0AppMetadata.resources || {};
      const grantedWorkspaces = resources[currentOrg.name] || {}; // List of workspaces the user has been granted access to. 

      try {
        const response = await axios.get(`${API_BASE_URL}/api/GetOrganizationWorkspaces?orgName=${currentOrg.name}`,
          config
        );
        const workspacesList = response.data;

        let filteredWorkspacesList;

        // If grantedWorkspaces list is empty then no access restrictions applied, user can access all workspaces in the org.
        if (grantedWorkspaces.length > 0) {
          // Filter workspacesList based on the user's access
          filteredWorkspacesList = workspacesList.filter(workspace => {
          // Use revised_workspace_name if it’s populated, otherwise fall back to workspace_name
          const nameToCheck = workspace.revised_workspace_name || workspace.workspace_name;

            // Check if the nameToCheck is in grantedWorkspaces
            return grantedWorkspaces.includes(nameToCheck);
        })
        } else {
          filteredWorkspacesList = workspacesList
        }

        setOrgWorkspaces(filteredWorkspacesList);
        setAPIError(null);
      } catch (error) {
        console.error('Error fetching workspaces:', error);
        setAPIError('Failed to fetch workspaces.');
        setOrgWorkspaces([]);
      } finally {
        setIsLoading(false);
      }
    } else {
      setOrgWorkspaces([]);
    }
  };

  // Fetch workspaces when the current organization changes
  useEffect(() => {
    fetchWorkspaces();
  }, [currentOrg, user]);

  const handleCreateWorkspace = async () => {
    setIsCreating(true);
    try {
      if (!currentOrg) {
        console.error('No organization selected.');
        setAPIError('Please select an organization.');
        return;
      }

      const orgName = currentOrg.name;
      const trimmedWorkspaceName = workspaceName.trim();

      if (!trimmedWorkspaceName) {
        console.error('Workspace name is required.');
        setAPIError('Please enter a workspace name.');
        return;
      }

      const existingNames = getExistingWorkspaceNames(tableData);

      // Check if the new workspace name is already in use
      if (existingNames.has(trimmedWorkspaceName.trim())) {
        alert('This name cannot be picked as it is already in use. Please choose a different name.');
        return;
      }

      const response = await axios.post(`${API_BASE_URL}/api/NewWorkspaceTrigger`,
        {
          orgName: orgName,
          workspaceName: trimmedWorkspaceName,
          createdBy: user.email
        },
        config);

      if (response.data) {
        console.log("Container found and virtual folder created successfully.");
        // Navigate to the initiation page for the new workspace automatically
        navigate(`/${orgName}/${trimmedWorkspaceName}/initiateWorkspace`);
      } else {
        console.error("Failed to create virtual folder:", response.data);
        setAPIError('Failed to create virtual folder in the organization container.');
      }
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      setAPIError('Failed to check blob container existence or create virtual folder.');
    } finally {
      setIsCreating(false);
    }
  };

  if (isLoadingOrgs) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'white'
      }}>
        <Spinner animation="border" variant="primary" style={{ width: '100px', height: '100px' }} />
      </div>
    );
  }

  const tableHeaders = [
    { label: 'Workspace Name', key: 'display_name' },
    { label: 'Created By', key: 'createdBy' },
    { label: 'Created At', key: 'createdAt' },
    { label: 'Last Modified', key: 'modifiedAt' },
    { label: 'Status', key: 'status' }
  ];

  // Generate table data from orgWorkspaces
  const tableData = orgWorkspaces.map(workspace => ({
    name: workspace.workspace_name,
    display_name: workspace.revised_workspace_name || workspace.workspace_name,
    createdBy: workspace.created_by,
    createdAt: workspace.created_at,
    modifiedAt: workspace.modified_at,
    status: workspace.active
  }));

  const getExistingWorkspaceNames = (tableData) => {
    const existingNames = new Set(); // Using a Set to ensure uniqueness

    tableData.forEach(workspace => {
      existingNames.add(workspace.name);
      existingNames.add(workspace.display_name);
    });

    return existingNames;
  };

  const handleRowClick = (rowData) => {
    const { name, status } = rowData; // Destructure to get workspace name and status
    const orgName = currentOrg.name;

    if (status === 'Config') {
      navigate(`/${orgName}/${name}/initiateWorkspace`); // Route to InitiateWorkspace
    } else if (status === 'Active') {
      navigate(`/${orgName}/${name}/workspace`); // Route to Workspace (assuming this is the correct route)
    }
  };

  // Define row actions, including the delete and rename actions
  const rowActions = (rowData) => {
    const { name } = rowData;

    return [
      {
        label: 'Rename',
        onClick: () => handleRename(name),
        disabled: !hasEditPermission,
      },
      {
        label: 'Duplicate',
        onClick: () => handleDuplicate(name),
        disabled: !hasCreatePermission,
      },
      {
        label: 'Delete',
        onClick: () => handleDelete(name),
        disabled: !hasDeletePermission,
      },
    ];
  };

  // Handle workspace renaming
  const handleRename = async (workspaceName) => {

    // Prompt user for the new workspace name
    const newWorkspaceName = prompt("Enter a new name for the workspace:");

    // Validate the new name
    if (!newWorkspaceName || newWorkspaceName.trim() === "") {
      alert("Invalid name. Please enter a valid new name.");
      return;
    }

    const existingNames = getExistingWorkspaceNames(tableData);

    // Check if the new workspace name is already in use
    if (existingNames.has(newWorkspaceName.trim())) {
      alert('This name cannot be picked as it is already in use. Please choose a different name.');
      return;
    }

    try {
      // Call the RenameWorkspaceTrigger API to rename the workspace
      const response = await axios.post(
        `${API_BASE_URL}/api/RenameWorkspaceTrigger?orgName=${currentOrg.name}&workspaceName=${workspaceName}&newWorkspaceName=${newWorkspaceName}`,
        {}, // Empty payload for rename request
        config
      );

      if (response.status !== 200) {
        throw new Error("Failed to rename the workspace.");
      }

      // Refresh workspaces after renaming or provide success feedback
      await fetchWorkspaces();
    } catch (error) {
      console.error("Error renaming workspace:", error);
      alert("An error occurred while renaming the workspace.");
    }
  };

  const handleDuplicate = async (workspaceName) => {

    // Prompt user for the new workspace name for duplication
    const newWorkspaceName = prompt("Enter a new name for the duplicated workspace:");

    // Validate the new name
    if (!newWorkspaceName || newWorkspaceName.trim() === "") {
      alert("Invalid name. Please enter a valid new name.");
      return;
    }

    const existingNames = getExistingWorkspaceNames(tableData);

    // Check if the new workspace name is already in use
    if (existingNames.has(newWorkspaceName.trim())) {
      alert('This name cannot be picked as it is already in use. Please choose a different name.');
      return;
    }

    try {
      // Call the DuplicateWorkspaceTrigger API to duplicate the workspace
      const response = await axios.post(
        `${API_BASE_URL}/api/DuplicateWorkspaceTrigger?orgName=${currentOrg.name}&workspaceName=${workspaceName}&newWorkspaceName=${newWorkspaceName}`,
        {}, // Empty payload for duplicate request
        config
      );

      if (response.status !== 200) {
        throw new Error("Failed to duplicate the workspace.");
      }

      // Refresh workspaces after duplication or provide success feedback
      await fetchWorkspaces();
    } catch (error) {
      console.error("Error duplicating workspace:", error);
      alert("An error occurred while duplicating the workspace.");
    }
  };

  // Handle workspace deletion
  const handleDelete = async (workspaceName) => {

    // Prompt the user for confirmation before deletion
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this workspace? This action cannot be undone, and all workspace session data will be permanently deleted. Are you sure you want to proceed?"
    );

    if (!userConfirmed) return;

    try {
      // Call the DeleteWorkspaceTrigger API to delete the workspace
      const response = await axios.post(
        `${API_BASE_URL}/api/DeleteSession?containerName=${currentOrg.name}&virtualFolder=${workspaceName}`,
        {}, // Empty payload for delete request
        config
      );

      if (response.status !== 200) {
        throw new Error("Failed to delete the workspace.");
      }

      // Refresh workspaces after deletion or provide success feedback
      await fetchWorkspaces();
    } catch (error) {
      console.error("Error deleting workspace:", error);
      alert("An error occurred while deleting the workspace.");
    }
  };


  return (
    <Box style={{maxHeight: '100vh', minHeight: '100vh', display: 'flex', flexDirection: 'column', margin: 0, padding: 0, backgroundColor: 'ghostwhite', position: 'relative'}}>
    <div >

      <HeaderBar
        className="secondary-background"
        homeLink="/"
        homeText="hoppa"
        organizations={organizations}
        userInfo={user}
        onOrgSwitch={handleOrgSwitch} // Pass the callback to HeaderBar
        switchingAllowed={true}
      />


      <div className="full-width-white-section">
        <Container>
          <span style={{ fontSize: "1.1rem" }}>
            <Badge className="mt-4" bg="info">{currentOrg ? `${currentOrg.display_name}` : 'Dashboard'}</Badge>
          </span>
        </Container>

      </div>
      <Tab.Container defaultActiveKey="workspaces">
        <div className="full-width-white-bottom-section">
          <Container>
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="workspaces">
                  <WorkspacesIcon style={{ marginRight: '8px' }} />
                  Workspaces
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="blueprints">
                  <CategoryIcon style={{ marginRight: '8px' }} />
                  Blueprints
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="queries" disabled>
                  <SearchIcon style={{ marginRight: '8px' }} />
                  Query Forge
                  <Chip label='Coming Soon' size='small' variant='outlined' sx={{color: '#544cfd', borderColor: '#544cfd', marginLeft: '5px'}}/>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="settings" disabled>
                  <SettingsIcon style={{ marginRight: '8px' }} />
                  Settings
                  <Chip label='🚫' size='small' variant='outlined' sx={{color: '#544cfd', borderColor: '#fd664c', marginLeft: '5px'}}/>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Container>
        </div>
        <div className="full-width-lt-blue-section">
          <Container>
            <Tab.Content>

              <Tab.Pane eventKey="workspaces">
                {/* Existing workspace logic goes here */}

                {isLoading ? (
                  <Spinner animation="border" variant="primary" />
                ) : error ? (
                  <Alert variant="danger">{error}</Alert>
                ) : (
                  <DynamicTable
                    tableData={tableData} // Pass table data directly
                    tableHeaders={tableHeaders} // Pass table headers
                    searchPlaceholder="Search workspaces..." // Placeholder for the search box
                    onRowClick={handleRowClick} // Pass the row click handler
                    isLoading={isLoading}
                    actionButton={{
                      label: 'Create Workspace', // Label for the button
                      disabled: !hasCreatePermission,
                      onClick: () => setShowModal(true), // Function to call on click
                    }}
                    rowActions={(rowData) => rowActions(rowData)}
                  />
                )
                }
              </Tab.Pane>


              {/* Add placeholders for other tabs */}
              <Tab.Pane eventKey="blueprints">
                <Blueprints />
                {/* Add your subscription content here */}
              </Tab.Pane>
              <Tab.Pane eventKey="queries">
                <h3>User Management</h3>
                {/* Add your user management content here */}
              </Tab.Pane>
              <Tab.Pane eventKey="settings">
                <h3>Settings</h3>
                {/* Add settings content here */}
              </Tab.Pane>
            </Tab.Content>
          </Container>
        </div>

      </Tab.Container>


      {/* Modal for entering new workspace name */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Workspace</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="workspaceName">
              <Form.Label>Workspace Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter workspace name"
                value={workspaceName}
                onChange={(e) => setWorkspaceName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleCreateWorkspace}
            disabled={isCreating || !workspaceName.trim()} // Disable button if no name or during creation
          >
            {isCreating ? 'Creating...' : 'Create Workspace'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    </Box>
  );
};

export default OrganisationLanding;
